import React from 'react';

const Footer = () => {

    return (
        <footer className="footer">
            <div className="container text-center 2xl:flex 2xl:justify-between 2xl:flex-row-reverse py-4 2xl:py-6">
                <ul className="social 2xl:min-w-[158px]" />
                <ul className="footer-menu">
                    <li><a href="http://myaldi.com.au/" target="_blank" rel="noreferrer">MyALDI.com.au</a></li>
                    <li><a href="https://www.aldi.com.au/" target="_blank" rel="noreferrer">ALDI Supermarkets</a></li>
                    <li><a href="https://www.aldi.com.au/privacy-policy/" target="_blank" rel="noreferrer">Privacy Policy</a></li>
                    <li><a href="https://www.aldi.com.au/data-protection/" target="_blank" rel="noreferrer">Data Protection</a></li>
                    <li><a href="https://www.aldi.com.au/legal-notice/" target="_blank" rel="noreferrer">Legal Notice</a></li>
                    <li><a href="https://security.aldi-sued.com/" target="_blank" rel="noreferrer">Security Notice</a></li>
                </ul>
                <div className="copyright 2xl:min-w-[158px] 2xl:text-left">
                    <a href="https://www.aldi.com.au/" target="_blank" rel="noreferrer">© 2024 by ALDI</a>
                </div>
            </div>
        </footer>
    );
};

export default Footer;
