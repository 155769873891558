const DATA_URL = 'https://a3pl499ihc.execute-api.ap-southeast-2.amazonaws.com/adventapi/prod/';

export const getUserData = (userId) => {
    return fetch(DATA_URL + 'getUserStats?id=' + userId)
        .then(data => data.json())
};

export const getResultsData = () => {
    return fetch(DATA_URL + 'getResults')
        .then(data => data.json())
};
